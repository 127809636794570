<template>
  <page-view class="articles-detail" :up-class="'articles-detail'">
    <!-- pc -->
    <div class="pc_container" v-if="!isMobile">
      <div class="articles-title">
        <div class="left" @click="toMyShops">
          <img :src="comInfo?.logo" alt="" />
          <div class="name">{{ comInfo?.name }}</div>
          <!-- <div class="btn">进入店铺</div> -->
        </div>
      </div>

      <div class="content_container">

        <div class="container-s">
          <div>
            <div>
              <div>
                <div>材料优选</div>
                <div>|</div><img height="20px  " src="../assets/icon/dian.png" />
              </div>
              <div class="introduce_title" v-if="goodsDetails.name">{{ goodsDetails.name }}</div>
              <div class="introduce_content" v-if="goodsDetails.description">{{ goodsDetails.description }}</div>
              <div style="color: red;font-size: 1.4vw;" v-if="goodsDetails.price"><span style="color: #818181;font-size: 1vw;">价格 : </span> ￥{{ goodsDetails.price }}</div>
            </div>

            <div v-if="goodsImgArr && goodsImgArr.length > 0">
              <div><img src="../assets/icon/401.png"/><span>产品主图</span></div>
              <div>
                <div v-for="(goodsImg, index) in goodsImgArr" :key="index" @mouseenter="handleImg(index)">
                  <img :src="goodsImg" :class="{ active: currentGoodsIndex === index }" />
                </div>
              </div>
            </div>

            <div>
              <div><img src="../assets/icon/409.png"/><span>其他产品</span><span class="my-more">更多&nbsp;<span style="font-size: 2vw;position: absolute;top:0%;transform: translateY(-0.9vw);">›</span></span></div>
              <div>
                <div class="recommend_item" v-for="goods in recommendGoods" :key="goods.id" @click="toDetails(goods)">
                  <img :src="goods.image" />
                </div>
              </div>
            </div>
          </div>

          <div v-if="goodsImgArr">
            <img :src="goodsImgArr[currentGoodsIndex]" />
            <div class="materials_title">商品详情</div>
            <div v-html="detailsImage" class="materials_html"></div>
          </div>
        </div>
      </div>

    </div>

    <!-- 手机 -->
    <div class="mobile_container" v-else>
      <div class="top">
        <div class="left" @click="toMyShops">
          <img :src="comInfo?.logo" alt="" />
          <div class="name">{{ comInfo?.name }}</div>
          <div class="btn">进入店铺</div>
        </div>
      </div>
      <div class="banner_container">
        <div class="banner">
          <img :src="goodsImgArr[currentGoodsIndex]" />
        </div>
        <div class="banner_list">
          <img :src="item" v-for="(item, index) in goodsImgArr" :key="index" @click="handleImg(index)" />
        </div>
      </div>
      <!-- <div class="goods_introduce_container">
        <div class="price">¥ {{ goodsDetails.price }}<span id="star" @click="toCollect"></span></div>
        <div class="name">{{ goodsDetails.name }}</div>
        <div class="description">{{ goodsDetails.description }}</div>
      </div> -->
      <div class="specification_container" v-if="allGoodsMarquee && allGoodsMarquee.length > 0">
        <div class="specification_item" v-for="item in allGoodsMarquee" :key="item.id">
          <span class="option_name">{{ item.name }}</span>
          <div class="option_list">
            <div class="option_item" :class="{
              active: currentSelect.split('/').includes(option),
              noGoods: isNoGoods(item.id, option),
            }" v-for="(option, index) in item.value" :key="index" @click="selectMarquee(item.id, option)">
              {{ option }}
            </div>
          </div>
        </div>
        <!-- <div class="select_number">
          <div>购买数量</div>
          <div class="numbers"><img @click="downNumber" src="../assets/icon/down.png" /><span>{{ selectNumber
          }}</span><img @click="selectNumber++" src="../assets/icon/up.png" /></div>
        </div> -->
        <!-- <div class="native-btn">
          <div class="btn" @click="toAddCar">加入购物车</div>
          <div class="btn" @click="toShop">立即购买</div>
        </div> -->
      </div>

      <!-- <div class="words">
        <span class="title">商品评价</span>
        <div class="goodsss">
          <span :class="pingLunIndex == 0 ? 'tt' : ''" @click="changePingLunIndex(0)">全部评价({{ pingLunList.length
          }})</span>
          <span :class="pingLunIndex == 3 ? 'tt' : ''" @click="changePingLunIndex(3)">好评({{ pingLun1List.length }})</span>
          <span :class="pingLunIndex == 1 ? 'tt' : ''" @click="changePingLunIndex(1)">差评({{ pingLun2List.length }})</span>
        </div>
        <ul class="uls">
          <li v-for="(item, index) in testList" :key="index">
            <div>评论内容&nbsp;&nbsp;:&nbsp;&nbsp;{{ item.goodsCommentContent }}</div>
            <div>产品描述&nbsp;&nbsp;:&nbsp;&nbsp;<el-rate style="display: inline-block;" disabled
                :value="item.goodsCommentGrade1" :colors="colors"></el-rate></div>
          </li>
        </ul>
      </div> -->
      <div class="recommend_goods">
        <span class="title">好物推荐</span>
        <div class="goods_list">
          <div class="goods_item" v-for="item in recommendGoods" :key="item.id" @click="toDetails(item)">
            <img :src="item.image" />
            <span class="goods_name">{{ item.name }}</span>
          </div>
        </div>
      </div>
      <div class="goods_details_image">
        <div class="title">商品详细</div>
        <div v-html="detailsImage" class="materials_html"></div>
      </div>
    </div>
    <footer-com></footer-com>
  </page-view>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "ArticlesDetail",
  data() {
    return {
      isMeta: false,
      //公司信息
      comInfo: null,
      // 商品详情
      goodsDetails: {},
      // 推荐商品
      recommendGoods: [],
      // 商品图
      goodsImgArr: [],
      // 当前展示的商品图下标
      currentGoodsIndex: 0,
      // 接口获取的商品规格
      getGoodsMarqueeData: [],
      // 处理后的全部商品规格
      allGoodsMarquee: [],
      // 全部规格id
      allGoodsMarqueeId: [],
      // 可选规格
      availableArr: [],
      // 选择的规格
      currentSelect: "",
      //选择的数量
      selectNumber: 1,
      dizhis: [],//地址数据
      isLogin: false,//是否登录
      pingLunList: [],//全部
      pingLun1List: [],
      pingLun2List: [],
      testList: [],
      pingLunIndex: 0,
      colors: ['#99A9BF', '#F7BA2A', '#FF9900']
    };
  },
  computed: {
    ...mapState(["screenWidth", "infoObj"]),
    isMobile() {
      if (this.screenWidth < 768) {
        return true;
      } else {
        return false;
      }
    },
    // 防止商品详情图片溢出
    detailsImage() {
      const introduction = this.goodsDetails.introduction;
      let htmlSrt = "";
      if (introduction) {
        htmlSrt = introduction.replace(
          /\<img/g,
          '<img style="width:100%;height:auto;"'
        );
      }
      return htmlSrt;
    },
    // 当前选择的规格对象
    selectGoodsInfo() {
      const obj = this.getGoodsMarqueeData.find((item) => {
        return item.specificationNames === this.currentSelect;
      });
      return obj;
    },
  },
  created() {
    this.getGoodsDetails(this.$route.params.goodsId);
    this.getGoodsList();
    if (window.localStorage.getItem('token') && window.localStorage.getItem('token') != '' && this.infoObj && this.infoObj.account != '' && this.infoObj.account.companyid != null) {
      this.isLogin = false
    } else {
      this.isLogin = true
    }
  },
  // mounted() {
  //   this.askCollect();
  // },
  watch: {
    screenWidth(newV, oldV) {
      if (newV < 768) {
        this.isMeta = true;
      } else {
        this.isMeta = false;
      }
    },
  },
  methods: {
    ...mapActions(["setNavIndex"]),
    //查看店铺
    toMyShops() {
      this.$router.push({
        name: "ComIndex",
        params: {
          syscompanyid: this.comInfo.companyid
        },
      });
    },
    // 获取好物推荐
    async getGoodsList() {
      const loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      const { code, data } = await this.$api.getGoodsListApi({
        categoryId: -3,
        size:3
      });
      loading.close();

      if (code === 200) {
        this.recommendGoods = data.list;
      } else {
        this.$message.error("获取推荐商品失败");
      }
    },


    // 获取商品详情---goodsDetails展示下标题 详细信息 最下方材料详情
    async getGoodsDetails(id) {
      const loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const { code, data } = await this.$api.getGoodsDetailsApi(id);
      loading.close();
      if (code === 200) {
        this.goodsDetails = data.goodsMain;
        document.title = `百川商展-材料优选-${data.goodsMain.name}`;
        this.askPingJia(0);
        this.comInfo = {
          name: data.goodsMain.companyName,
          companyid: data.goodsMain.companyid,
          logo: data.goodsMain.companyLogo,
        };
        // 商品图片
        const imgArr = data.goodsMain.productImages ? JSON.parse(data.goodsMain.productImages) : [];

        imgArr.forEach((item) => {
          this.goodsImgArr.push(this.isMeta ? item.details : item.large);
        });
      } else {
        this.$message.error("获取商品详情失败");
      }
    },
    // 获取商品规格
    // async getGoodsMarquee(id) {
    //   const loading = this.$loading({
    //     lock: true,
    //     text: "加载中...",
    //     spinner: "el-icon-loading",
    //     background: "rgba(0, 0, 0, 0.7)",
    //   });

    //   const { code, data } = await this.$api.getGoodsMarqueeApi(id);
    //   loading.close();

    //   if (code === 200) {
    //     this.getGoodsMarqueeData = data;//data是所有规格组合的全部可能
    //     this.allGoodsSpecification();// 需要显示的全部商品规格

    //     data.forEach((item) => {
    //       this.availableArr.push(item.specificationNames);
    //     });
    //     // 默认选择的规格
    //     this.currentSelect = this.availableArr[0];
    //   } else {
    //     this.$message.error("获取商品规格失败");
    //   }
    // },
    // // 处理后端返回的规格转化成可渲染的商品规格
    // allGoodsSpecification() {
    //   const obj = {};
    //   this.getGoodsMarqueeData.length > 0 &&  this.getGoodsMarqueeData.forEach((item) => {
    //     const specificationValues = item.specificationValues ? JSON.parse(item.specificationValues).SpecificationsWhole : [];
    //     specificationValues.forEach((tItem) => {
    //       if (!obj[tItem.id]) {
    //         obj[tItem.id] = {
    //           id: tItem.id,
    //           name: tItem.name,
    //           value: [tItem.value],
    //         };
    //       } else {
    //         obj[tItem.id].value.push(tItem.value);
    //       }
    //     });
    //   });
    //   // 去重
    //   for (const key in obj) {
    //     obj[key].value = obj[key].value.filter((item, index) => {
    //       return obj[key].value.indexOf(item) === index;
    //     });
    //     this.allGoodsMarquee.push(obj[key]);
    //     this.allGoodsMarqueeId.push(obj[key].id);
    //   }
    // },
    // 选择的规格
    // selectMarquee(id, value) {
    //   const index = this.allGoodsMarqueeId.indexOf(id);

    //   const arr = this.currentSelect.split("/");
    //   arr.splice(index, 1, value);
    //   if (!this.availableArr.includes(arr.join("/"))) return;

    //   this.currentSelect = arr.join("/");
    // },
    // 点击小图片
    handleImg(index) {
      this.currentGoodsIndex = index;
    },
    // 不可选商品
    isNoGoods(id, value) {
      const index = this.allGoodsMarqueeId.indexOf(id);
      const arr = this.currentSelect.split("/");
      arr.splice(index, 1, value);
      return !this.availableArr.includes(arr.join("/"));
    },

    //加入购物车
    async toAddCar() {
      if (this.isLogin) {
        this.setNavIndex("dddd");
        this.$router.push({ name: "Login", });
        return
      }
      const loading = this.$loading({
        lock: true,
        text: "正在加入购物车...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let arr = this.getGoodsMarqueeData.filter(item => item.specificationNames == this.currentSelect)
      let res = await this.$api.addShopCar({
        productId: arr[0].id,
        amount: arr[0].price,
        isadd: false,
        productNumber: this.selectNumber,
        companyid: this.comInfo.companyid
      })
      loading.close();
      if (res.code == 200) {
        this.$message.success('成功加入购物车')
      } else {
        this.$message.error('加入购物车失败')
      }
    },

    //立即购买
    async toShop() {
      if (this.isLogin) {
        this.setNavIndex("dddd");
        this.$router.push({ name: "Login", });
        return
      }
      const loading = this.$loading({
        lock: true,
        text: "正在生成订单...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let ress = await this.$api.getDiZhi()
      if (ress.code == 200) {
        if (ress.data.length == 0) {
          this.$message.error('您还没有收货地址，请先设置收货地址.')
          loading.close()
          return
        } else {
          let arr = ress.data.filter(item => item.isDefault == 1)
          this.dizhis = arr
        }
      }
      let css;
      this.getGoodsMarqueeData.forEach((item) => {
        if (item.specificationNames == this.currentSelect) {
          css = item.id
        }
      })
      let res = await this.$api.makeOrder({//生成订单
        myCompanyid: this.infoObj.companyid,
        addressId: this.dizhis[0].id,
        remarks: '',
        goodsOnSumList: JSON.stringify([{ goodId: css, goodsNumber: this.selectNumber, }]),
        couponsCode: null,
        memberId: this.infoObj.id,
        type: 1,
      })
      loading.close()
      if (res && res.code == 200) {
        let skipData = {// 支付所需参数
          // orderNo: res.data.orderNumber,//采购订单号（CG开头）
          summaryOrderNo: res.data.summaryOrderNo,//下单号（HB开头）
        };
        this.$router.push({
          name: "GiveMoney",
          query: { info: JSON.stringify(skipData) },
        });
      } else {
        this.$message.error("创建订单失败,请返回重试")
      }
    },

    downNumber() {//数量减减
      if (this.selectNumber <= 1) {
        return
      } else {
        this.selectNumber--
      }
    },

    //收藏和取消收藏
    async toCollect(e) {
      if (this.isLogin) {
        this.setNavIndex("dddd");
        this.$router.push({ name: "Login", });
        return
      }
      const loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const params = {
        id: this.$route.params.goodsId,
        type: 2,
        upvoteType: 2,
        shopping: 1,
      }

      let res;
      let text;
      if (e.target.classList.value == '') {
        res = await this.$api.addCollect(params)
        text = '添加'
        e.target.classList.add("fill")
      } else {
        res = await this.$api.delCollect(params)
        text = '取消'
        e.target.classList.remove("fill")
      }
      loading.close()
      if (res && res.code == 200) {
        this.$message.success(`${text}成功`)
      } else {
        this.$message.error(`操作失败,${res.msg}`)
      }
    },

    //访问收藏列表判断是否被收藏
    async askCollect() {
      if (this.islogin) {
        return
      }
      const res = await this.$api.getCollectListApi({
        type: 2,
        upvoteType: 2,
      })
      if (res.code === 200) {
        let a = res.data.some(item => item.id == this.$route.params.goodsId)
        if (a) {
          setTimeout(() => {
            document.querySelector('#star').classList.add("fill")
          }, 100);
        }
      }
    },

    //评价列表
    async askPingJia(index) {
      this.pingLunIndex = index
      let res = await this.$api.askPingJia(this.goodsDetails.id)
      this.pingLunList = res.data
      this.pingLun1List = res.data.filter(item => item.goodsCommentRank == 3)
      this.pingLun2List = res.data.filter(item => item.goodsCommentRank == 1)
      this.testList = this.pingLunList
    },

    changePingLunIndex(index) {
      this.pingLunIndex = index
      if (index == 3) {
        this.testList = this.pingLun1List
      } else if (index == 1) {
        this.testList = this.pingLun2List
      } else {
        this.testList = this.pingLunList
      }
    },
    toDetails(v) {
      console.log('vvv');
      const openRoute = this.$router.resolve({
        name: "ArticlesDetail",
        params: {
          goodsId: v.id,
        },
      });
      window.open(openRoute.href, "_blank");
    },
  },
};
</script>
<style lang="scss">
.articles-detail {

  .container-s {
    width: 80%;
    margin: auto;
    display: flex;
    gap: 3%;
    align-items: flex-start;

    >div:nth-child(1) {
      font-size: 0.9vw;
      flex: 3;
      background-color: white;
      padding: 3%;
      overflow: hidden;

      >div:nth-child(1) {
        >div {
          padding: 5% 0%;
        }

        >div:nth-child(1) {
          background-color: #439c4c;
          display: inline-flex;
          align-items: center;
          padding: 5px 15px;
          color: white;
          gap: 10px;
          border-radius: 20px;
          white-space: nowrap;
          box-sizing: border-box;
          margin-bottom: 2%;
        }

        .introduce_content {
          color: #8d8d8d;
          line-height: 1.5;
          letter-spacing: 1px;
        }

        .introduce_title {
          font-size: 1.3vw;
          font-weight: bold;
        }

        >div:not(:first-child) {
          border-bottom: 1px solid #dbdbdb;
        }
      }

      >div:nth-child(2) {
        padding: 8% 0%;
        border-bottom: 1px solid #dbdbdb;

        >div:nth-child(1) {
          color: black;
          font-weight: bold;
          padding-bottom: 4%;
          >img,span{
            vertical-align: middle;
          }
          >img{
            width: 15px;
            height: 15px;
            margin-right: 9px;
          }
        }

        >div:nth-child(2) {
          margin: 1% 0%;
          display: flex;
          gap: 1%;
          width: 100%;
          border-radius: 20px;
          overflow: hidden;

          >div {
            cursor: pointer;
            flex: 1;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            overflow: hidden;
            >img {
              width: 100%;
              aspect-ratio: 3/7;
              object-fit: cover;
            }
          }
        }
      }

      >div:nth-child(3) {
        margin-top: 7%;
        padding: 3%;
        box-sizing: border-box;
        border-radius: 10px;
        overflow: hidden;
        background-color: #F9FAFC;
        >div:nth-child(1) {
          color: black;
          font-weight: bold;
          margin-bottom: 4%;
          position: relative;
          >img,span{
            vertical-align: middle;
          }
          >img{
            width: 30px;
            height: 16px;
            margin-right: 9px;
          }
          .my-more{
            position: absolute;
            right: 2.5%;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
            font-weight: 400;
          }
        }

        >div:nth-child(2) {
          display: flex;
          gap: 2%;
          width: 100%;
          border-radius: 5px;
          overflow: hidden;

          >div {
            cursor: pointer;
            flex: 1;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            overflow: hidden;
            >img {
              width: 100%;
              aspect-ratio: 3/6;
              object-fit: cover;
            }
          }
        }
      }
    }

    >div:nth-child(2) {
      flex: 5;
      .materials_title{
        text-align: center;
        color:black;
        font-size: 1.3vw;
        font-weight: bold;
        padding: 3% 0%;
        position: relative;
        &::before {
          content: '';
          position: absolute;
          left: 6%;
          top: 50%;
          transform: translateY(-50%);
          height: 1px;
          width: 30%;
          background-color: rgb(185, 182, 182);
        }
        &::after {
          content: '';
          position: absolute;
          right: 6%;
          top: 50%;
          transform: translateY(-50%);
          height: 1px;
          width: 30%;
          background-color: rgb(185, 182, 182);
        }
      }
      >img {
        width: 100%;
      }
    }
  }

  .pc_container {
    background-color: #f6f1eb;

    .articles-title {
      padding: 20px 0;
      background-color: #fffcf7;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

      .left {
        margin-left: 6%;
        display: inline-flex;
        align-items: center;

        >img {
          width: 58px;
          height: 58px;
          cursor: pointer;
          object-fit: contain;
        }

        .name {
          font-size: 14px;
          margin: 0 20px;
          cursor: pointer;
        }

        .btn {
          background-color: #ffffff;
          box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.25);
          border-radius: 50px;
          padding: 6px 25px;
          font-size: 12px;
          cursor: pointer;
        }
      }
    }

    .content_container {
      padding: 2% 6%;
      box-sizing: border-box;


      .words {
        margin-top: 10px;

        .recommend_title {
          font-size: 28px;
        }

        .goodsss {
          margin: 1% 0%;

          >span {
            display: inline-block;
            margin-right: 3%;
            padding: 0.5% 1%;
            cursor: pointer;
            border: 1px solid #ddd;
            background-color: #fff;
            color: #a1a0a0;
            user-select: none;
          }

          .tt {
            color: #595959;
          }
        }

        .uls {
          font-size: max(14px, 1vw);

          >li {
            margin-bottom: 1vw;

            >div:nth-child(2) {
              display: flex;
              align-items: center;
            }
          }
        }

      }


      .materials_details {
        width: 100%;
        margin-top: 30px;

        .materials_title {
          font-size: 28px;
        }

        .materials_html {
          margin-top: 20px;
        }
      }
    }
  }

  .mobile_container {
    width: 100%;
    background-color: #f5f5f5;

    .top {
      .left {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.2rem 0.5rem;
        box-sizing: border-box;
        background-color: #fffcf7;
      }


      img {
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        object-fit: contain;
      }

      .btn {
        padding: 0.1rem 0.5rem;
        background-color: #fff;
        border-radius: 20px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      }
    }

    .banner_container {
      .banner {
        width: 100%;
        height: 4.5rem;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .banner_list {
        width: 100%;
        height: 1.7rem;
        padding: 0.1rem;
        box-sizing: border-box;
        display: flex;
        overflow: hidden;
        overflow-x: auto;

        img {
          width: 1.4rem;
          height: 1.4rem;
          border-radius: 5px;
          margin-right: 0.2rem;
        }
      }
    }

    .goods_introduce_container {
      width: calc(100% - 20px);
      background-color: #fffcf7;
      padding: 0.2rem;
      margin: 10px auto;
      box-sizing: border-box;

      #star {
        display: block;
        width: 30px;
        height: 30px;
        background-image: url(../assets/icon/collect.png);
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center;
        cursor: pointer;
        margin-top: 3%;
      }

      .fill {
        position: relative;
        animation: banuce 0.2s ease 0s 2 alternate;
        background-image: url(../assets/icon/collect2.png) !important;

        &::before {
          opacity: 0;
          content: '';
          position: absolute;
          left: 50%;
          top: 50%;
          width: 10%;
          height: 10%;
          transform: translate(-50%, -50%);
          border-radius: 50%;
          background: transparent;
          box-shadow: 15px -15px 0 #fed001, 15px 15px 0 #fed001,
            -15px -15px 0 #fed001, -15px 15px 0 #fed001;
          animation: show 0.2s steps(1, end) 0s 1;
        }

        &::after {
          opacity: 0;
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          animation: circle 0.3s ease 0.02s 1 alternate;
          border-radius: 50%;
          background: rgba(254, 208, 1, 0.1);
        }

        @keyframes banuce {
          0% {
            transform: scale(0.8);
          }

          100% {
            transform: scale(1.35);
          }
        }

        @keyframes circle {
          0% {
            transform: scale(0.2);
            opacity: 0.8;
          }

          100% {
            transform: scale(1.5);
            opacity: 1;
          }
        }

        @keyframes show {
          0% {
            opacity: 1;
          }
        }
      }

      .price {
        font-size: 0.4rem;
        color: #ff0000;
      }

      .name {
        font-size: 0.4rem;
      }

      .description {
        color: #979797;
        font-size: 0.2rem;
        margin-top: 10px;
      }
    }

    .specification_container {
      width: calc(100% - 20px);
      background-color: #fffcf7;
      padding: 0.2rem;
      margin: 0 auto 10px;
      box-sizing: border-box;


      .native-btn {
        padding: 2% 0%;
        display: flex;
        justify-content: space-between;

        .btn {
          background: #439c4c;
          border-radius: 0.5vw;
          color: #fff;
          text-align: center;
          padding: 2% 8%;
          cursor: pointer;
        }
      }

      .select_number {
        font-size: 14px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .numbers {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          >span {
            display: inline-block;
            padding: 0% 3%;
          }

          >img {
            height: 20px;
            cursor: pointer;

            &:active {
              transform: scale(0.95);
            }
          }
        }
      }

      .specification_item {
        margin-bottom: 10px;

        .option_name {
          font-size: 0.3rem;
          font-weight: bold;
        }

        .option_list {
          display: flex;
          flex-wrap: wrap;

          .option_item {
            min-width: 1rem;
            min-height: 1rem;
            font-size: 0.2rem;
            color: #a2afa3;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 0.2rem;
            margin-top: 10px;
            padding: 0 0.1rem;
            box-sizing: border-box;
            border: 1px solid #439c4c;

            &.active {
              color: #fff;
              background-color: #439c4c;
            }

            &.noGoods {
              color: #fff;
              background-color: #d7d7d7;
              border-color: transparent;
            }
          }
        }
      }
    }

    .recommend_goods {
      width: calc(100% - 20px);
      margin: 0 auto 10px;
      padding: 0.2rem;
      box-sizing: border-box;
      background-color: #fffcf7;

      .title {
        font-size: 0.4rem;
      }

      .goods_list {
        width: 100%;
        padding: 0.2rem 0;
        box-sizing: border-box;
        display: flex;
        overflow: hidden;
        overflow-x: auto;

        .goods_item {
          display: flex;
          flex-direction: column;
          margin-right: 10px;

          img {
            width: 3rem;
            height: 3rem;
          }

          .goods_name {
            margin-top: 10px;
            font-size: 0.3rem;
          }
        }
      }
    }



    .words {
      width: calc(100% - 20px);
      margin: 0 auto 10px;
      padding: 0.2rem;
      box-sizing: border-box;
      background-color: #fffcf7;

      .title {
        font-size: 0.4rem;
      }

      .goodsss {
        margin-top: 3%;

        >span {
          display: inline-block;
          margin-right: 3%;
          padding: 0.5% 1%;
          cursor: pointer;
          border: 1px solid #ddd;
          background-color: #fff;
          color: #a1a0a0;
          user-select: none;
        }

        .tt {
          color: #595959;
        }
      }

      .uls {
        font-size: max(14px, 1vw);

        >li {
          margin: 4% 0%;

          >div:nth-child(2) {
            display: flex;
          }
        }
      }
    }

    .goods_details_image {
      width: 100%;
      background-color: #fffcf7;

      .title {
        font-size: 0.4rem;
        text-align: center;
        line-height: 1rem;
      }
    }
  }
}
</style>
